<template>
  <div>
    <v-btn
      v-for="(item, i) in items" :key="i"
      fab
      class="ma-2"
      small
      :href="item.link"
      target="_blank"
    >
      <v-icon
        color="slenDblue">
        {{ item.icon }}
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
  export default {

    data: () => ({
      items: [
        {
          name: 'LinkedIn',
          icon: 'mdi-linkedin',
          link: 'https://de.linkedin.com/company/slenderiser-gmbh'
        },
        {
          name: 'Xing',       
          icon: '$xing',
          link: 'https://www.xing.com/pages/slenderisergmbh'
        },
      ],
    }),
  }
</script>